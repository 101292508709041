import React, { useMemo, useState } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { TableOptions, DataTableData } from 'types';
import { config, locationService } from '@grafana/runtime';
import { Icon } from '@grafana/ui';
import './css/infoTable.css';

interface Props extends PanelProps<TableOptions> {}

export const infoTablePanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  const invertThreshold = options.invertThreshold;
  const warningColor = replaceVariables(options.warningColor);
  const metricUnit = replaceVariables(options.metricUnit);
  var count = 0;
  
  const memoizedOptions = useMemo(() => ({
    data: data,
  }), [data]);

  if (width < 75 || height < 75) {
    return (
	  <div className="infoTableErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (memoizedOptions.data.state === 'Error') {
    return (
	  <div className="infoTableErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (memoizedOptions.data.series[0].length < 1) {
    return (
	  <div className="infoTableErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  let base_url = replaceVariables(options.drillDownLink);
  if (base_url !== null || base_url !== undefined) {
    base_url = '';
  }
  let separator = options.separator;
  if (separator === null || separator === undefined) {
    separator = '';
  }
  const tables: DataTableData[] = [];
  memoizedOptions.data.series.forEach(series => {
	const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < timeVals.length; i++) {
	  let tableData: DataTableData = {
		id: i,
		tag: series.fields.find(field => field.name === options.tableTagOption)?.values.get(i),
		value: series.fields.find(field => field.name === options.tableValueOption)?.values.get(i),
		threshold: series.fields.find(field => field.name === options.tableThresholdOption)?.values.get(i),
		style: series.fields.find(field => field.name === options.tableStyleOption)?.values.get(i),
		icon: series.fields.find(field => field.name === options.tableIconOption)?.values.get(i),
		title: series.fields.find(field => field.name === options.tableTitleOption)?.values.get(i),
		url: series.fields.find(field => field.name === options.tableUrlOption)?.values.get(i),
		target: series.fields.find(field => field.name === options.tableUrlTargetOption)?.values.get(i),
		variableName: series.fields.find(field => field.name === options.tableVariableNameOption)?.values.get(i),
		variableValue: series.fields.find(field => field.name === options.tableVariableValueOption)?.values.get(i),
	  };
	  if (tableData.value === null || tableData.value === undefined) {
		tableData.value = 0;
	  }
	  if (tableData.threshold === null || tableData.threshold === undefined || isNaN(tableData.threshold)) {
		tableData.threshold = 0;
	  }
	  if (tableData.style === null || tableData.style === undefined || tableData.style === '') {
		tableData.style = 'default';
	  }
	  if (tableData.icon === null || tableData.icon === undefined || tableData.icon === '') {
		tableData.icon = '-';
	  }
	  if (String(separator).length > 0) {
		tableData.tag = tableData.tag + separator;
	  }
	  if (tableData.title === null || tableData.title === undefined) {
		tableData.title = tableData.tag;
	  }
	  if (tableData.target === null || tableData.target === undefined || tableData.target !== '_self') {
		tableData.target = '_blank';
	  }
	  if (tableData.url === null || tableData.url === undefined || String(tableData.url).length < 1) {
		tableData.url = '';
		tableData.target = '';
	  }
	  if (base_url !== '') {
		tableData.url = base_url + tableData.url;
	  }
	  if (tableData.variableName === null || tableData.variableName === undefined || String(tableData.variableName).length < 1) {
		tableData.variableName = '';
		tableData.variableValue = '';
	  }
	  if (tableData.variableValue === null || tableData.variableValue === undefined || String(tableData.variableValue).length < 1) {
		tableData.variableValue = '';
	  }
	  tables.push(tableData);
	}
  });

  if (options.selectFirst && count < 1) {
    if ((!options.useHeader && tables.length > 0) || (options.useHeader && tables.length > 1)) {
	  count = 1;
	  const firstRow = options.useHeader ? tables[1] : tables[0];
	  if (firstRow.url !== '' && firstRow.target !== '') {
	    window.open(firstRow.url, firstRow.target, 'nofollow, noopener, noreferrer');
      } else if (firstRow.variableName && firstRow.variableName !== '') {
	    let queryMap = {
	      [`var-${firstRow.variableName}`]: firstRow.variableValue,
	    };
	    changeUrl(queryMap);
	  }
    }
  }
  return (
    <table 
	  className={isDark ? 'infoTable_container_dark' : 'infoTable_container_light'}
	  style={{ width: width - 10, borderSpacing: '0px ' + options.rowSpacer + 'px' }}
	>
	  {rowFactory(
	    tables,
		absoluteThreshold,
		options.invertThreshold,
		warningColor,
		metricUnit,
		options.fontSize,
		options.useHeader,
		options.alignLeft,
		options.rowHeight,
		options.rowRadius,
		options.rowSpacer
	  )}
	</table>
  );
});

function rowFactory(
  tableData: DataTableData[],
  absoluteThreshold: number,
  invertThreshold: boolean,
  warningColor: string,
  metricUnit: string,
  textFontSize: number,
  useHeader: boolean,
  alignLeft: boolean,
  rowHeight: number,
  radius: number,
  rowSpacer: number
) {
  const theme = config.theme.isDark ? '_dark' : '_light';
  const handleRowClick = (row) => {
    if ((row.id === 0 && !useHeader) || row.id !== 0) {
	  if (row.url !== '' && row.target !== '') {
	    window.open(row.url, row.target, 'nofollow, noopener, noreferrer');
      } else if (row.variableName && row.variableName !== '') {
	    let queryMap = {
	      [`var-${row.variableName}`]: row.variableValue,
	    };
	    changeUrl(queryMap);
      }
	}
  }
  const styleMap = {
    '%r': 'red',
    '%g': 'green',
    '%o': 'orange',
    '%w': 'white',
    '%b': 'black',
    '%y': 'yellow',
    '%c': 'blue',
    '%l': 'grey',
	'%t': 'default',
    '%i': 'iconCell',
  };
  let iconSize = 'xxl';
  if (rowHeight < 24) {
	iconSize = 'md';
  } else if (rowHeight < 30) {
	  iconSize = 'lg';
  } else if (rowHeight < 38) {
	  iconSize = 'xl';
  }

  return tableData.map(row => {
	const isHeader = row.id === 0 && useHeader ? true: false;
    const actualValue = isNaN(row.value) ? null : Number(row.value);
	const hasLUrlLink = (row.url !== '' && row.target !== '') || (row.variableName && row.variableName !== '') ? true : false;
    const linkStyleClass = hasLUrlLink ? `infoTable_link${theme} ` : '';

    const isAlarmed = (
      (actualValue > absoluteThreshold && !invertThreshold) ||
	  (actualValue < absoluteThreshold && invertThreshold) ||
      (actualValue > row.threshold && !invertThreshold) ||
	  (actualValue < row.threshold && invertThreshold)  
	) ? true : false;

    const cells = row ? (row.tag.split('!!').map((tag, index) => {
	  let styleClass = alignLeft ? 
	    linkStyleClass + 'infoTable_cell infoTable_default' + theme :
		linkStyleClass + 'infoTable_cell_center infoTable_default' + theme;
      let cellContent;
	
      if (isHeader) {
        let hasContent = false;
		for (const color in styleMap) {
          if (tag.startsWith(color)) {
		    styleClass = alignLeft ? `infoTable-rowTitleCell${theme}` : `infoTable-rowTitleCell${theme} infoTable_cell_center`;
		    const content = tag.replace(color, '');
			if (color === '%i') {
              cellContent = (
                <th 
				  key={index} 
				  className={`infoTable-iconTitleCell${theme} infoTable_cell_center`}
				>
				  {content.length > 0 && content !== '' && !content.startsWith('%') && (
				    <Icon name={content} size={iconSize}/>
				  )}
                </th>
              );
			  hasContent = true;
            } else {
              cellContent = (
                <th 
				  key={index} 
				  className={styleClass} 
				  style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}
				>
                  {content.startsWith('%') ? '' : content}
                </th>
              );
			  hasContent = true;
            }
            break;
		  }
		}
        if (!hasContent) {
		  styleClass = alignLeft ? `infoTable-rowTitleCell${theme}` : `infoTable-rowTitleCell${theme} infoTable_cell_center`;
		  cellContent = (
			<th key={index} className={styleClass} style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}>
			  {tag}
			</th>
          );
		}
	  } else {
        let hasContent = false;
        for (const color in styleMap) {
          if (tag.startsWith(color)) {
            styleClass = alignLeft ? 
		      `${linkStyleClass} infoTable_cell infoTable_${styleMap[color]}${theme}` : 
			  `${linkStyleClass} infoTable_cell_center infoTable_${styleMap[color]}${theme}`;
            const content = tag.replace(color, '');
            if (color === '%i') {
			  let iconName = content !== '-' ? content : '';
			  let iconClass = alignLeft ? 
			    'infoTable_cell infoTable-rowValue' + theme + ' infoTable_iconCell' + theme :
				'infoTable_cell_center infoTable-rowValue' + theme + ' infoTable_iconCell' + theme;
			  if (iconName !== '') {
			    for (const color in styleMap) {
				  if (iconName.startsWith(color)) {
				    iconClass = `${iconClass} infoTable_${styleMap[color]}${theme}`;
				    iconName = iconName.replace(color, '');
				    break;
				  }
			    }
			  }
              cellContent = (
                <td 
				  key={index} 
				  className={isAlarmed ? iconClass + ' infoTable-rowAlarmed' + theme : iconClass} 
				  title={row.title}
				>
				  {iconName.length > 0 && (
				    <Icon name={iconName} size={iconSize}/>
				  )}
                </td>
              );
			  hasContent = true;
            } else {
			  cellContent = (
                <td 
				  key={index}
				  className={isAlarmed ? styleClass + ' infoTable-rowAlarmed' + theme : styleClass}
				  title={row.title}
				  style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}
				>
                  {content}
                </td>
              );
			  hasContent = true;
            }
            break;
          }
        }
        if (!hasContent) {
		  cellContent = (
            <td 
			  key={index} 
			  className={isAlarmed ? styleClass + ' infoTable-rowAlarmed' + theme : styleClass} 
			  title={row.title}
			  style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}
			>
              {tag}
            </td>
          );
        }
	  }
      return cellContent;
    })) : (() => {
      return (
		<th 
		  key={'0'} 
		  className={alignLeft ? 'infoTable_cell infoTable_default' + theme : 'infoTable_cell_center infoTable_default' + theme} 
		  style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}
		>
		  {'-'}
		</th>
	  );
	});

	let valueClass = alignLeft ?
  	  'infoTable_cell infoTable_' +  row.style + theme :
	  'infoTable_cell_center infoTable_' +  row.style + theme;
	const linkColor = config.theme.isDark ? '#557FFF': '#6C63FE';
	const valueTextColor = (row.url !== '' && row.target !== '') ? linkColor : 'currentColor';
	const valueFontWeight = (row.url !== '' && row.target !== '') ? '500': '400';

	const shadowColor = config.theme.isDark ? '#23282E': '#E6E9ED';
	
	let iconName = row.icon;
	let iconClass = alignLeft ?
	  'infoTable_cell infoTable-rowValue' + theme + ' infoTable_iconCell' + theme :
	  'infoTable_cell_center infoTable-rowValue' + theme + '  infoTable_iconCell' + theme;
	if (iconName !== '-') {
	  for (const color in styleMap) {
	    if (iconName.startsWith(color)) {
	      iconClass = `${iconClass} infoTable_${styleMap[color]}${theme}`;
		  iconName = iconName.replace(color, '');
		  break;
		}
	  }
	}

    return (
      <tr 
	    key={row.id}
		className={hasLUrlLink ? 'infoTable-hasLink infoTable-rowLink' + theme : 'infoTable-rowLink' + theme}
		onClick={() => handleRowClick(row)}
		style={rowSpacer > 1 ?
		  { fontSize: textFontSize + 'px', height: rowHeight + 'px', borderRadius: radius + 'px', boxShadow: '2px 2px 6px 0px ' + shadowColor } :
		  { fontSize: textFontSize + 'px', height: rowHeight + 'px', borderRadius: radius + 'px' }
		}
	  >
		{isHeader && row.icon !== '-' && (
		  <th
		    key={'tableIcon' + row.id} 
		    className={alignLeft ? 
			  'infoTable_cell infoTable-rowValue' + theme + ' infoTable_iconHeader' + theme :
			  'infoTable_cell_center infoTable-rowValue' + theme + ' infoTable_iconHeader' + theme
			}
			style={{ borderRadius: radius + 'px 0px 0px ' + radius + 'px' }}
		  >
		    {iconName.length > 0 && (
			  <Icon name={iconName} size={iconSize}/>
			)}
		  </th>
		)}
		{!isHeader && row.icon !== '-' && (
		  <td 
		    key={'tableIcon' + row.id} 
			className={isAlarmed ? iconClass + ' infoTable-rowAlarmed' + theme : iconClass} 
			style={{ borderRadius: radius + 'px 0px 0px ' + radius + 'px' }}
		    title={row.title}
		  >
		    {iconName.length > 0 && (
			  <Icon name={iconName} size={iconSize}/>
			)}
		  </td>
		)}
		{cells}
		{isHeader && row.value && (
		  <th 
		    key={'tableValue' + row.id} 
		    className={alignLeft ? 
			  'infoTable_cell infoTable-rowTitleCell' + theme :
			  'infoTable_cell_center infoTable-rowTitleCell' + theme
			}
			style={alignLeft ? { paddingLeft: 5 } : { paddingLeft: 0 }}
		  >
		    {row.value}
		  </th>
		)}
		{!isHeader && row.value && (
		  <td 
		    key={'tableValue' + row.id} 
		    className={isAlarmed ? valueClass + ' infoTable-rowAlarmed' + theme : valueClass}
		    style={isAlarmed ? { 
			    color: warningColor,
				fontSize: textFontSize + 2 + 'px',
				fontWeight: '500', borderRadius: '0px ' + radius + 'px ' + radius + 'px 0px'
			  } : { 
				color: valueTextColor,
				fontWeight: valueFontWeight,
				borderRadius: '0px ' + radius + 'px ' + radius + 'px 0px' 
			  }
		    }
		    title={row.title}
		  >
		    {row.value + ' ' + metricUnit}
		  </td>
		)}
      </tr>
    );
  });
}

function changeUrl(queryMap) {
  locationService.partial(queryMap, true);
}